import { tokenAuthApiInstance } from 'utils/auth';

import { REQUEST_PROXY_ACCESS } from './constants';

export const submitProxyAccessRequest = async (payload: FormData): Promise<string> => {
  const response = await tokenAuthApiInstance
    .authAxios(undefined, 'v0', { 'Content-type': 'multipart/form-data' })
    .post(REQUEST_PROXY_ACCESS, payload);
  return response.data;
};
