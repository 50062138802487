import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { submitProxyAccessRequest } from 'services';

export type State = ApiState<string, SerializedError>;

export const postProxyAccessRequest = createAsyncThunk(
  'post/requestProxyAccess',
  async (payload: FormData) => await submitProxyAccessRequest(payload)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: '',
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const proxyRequestPayloadSlice = createSlice({
  name: 'proxyRequestPayload',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    resetProxyRequestPayload: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(postProxyAccessRequest.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(postProxyAccessRequest.fulfilled, (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.data = action.payload;
    });

    builder.addCase(postProxyAccessRequest.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { proxyRequestPayload: State }): State => state.proxyRequestPayload;

export const requestProxyPayload_ = createSelector(stateSelector_, (state) => {
  return state.data;
});

export const proxyRequestSaveHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);
export const proxyRequestSaveIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const { resetProxyRequestPayload } = proxyRequestPayloadSlice.actions;

export default proxyRequestPayloadSlice.reducer;
