import './cpx-header.component.styles.scss';

import { Avatar, Box, Button, List, ListItem, ListItemIcon, ListItemText, Popover } from '@material-ui/core';
import { AccountCircle, Done } from '@material-ui/icons';
import { AuthContext } from 'context/authContext';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CurrentPatientView_,
  MainPatientAccount_,
  setMainAccount,
  setUserSwitchedAccount,
  setUserViewing,
} from 'reducers/current-patient-view.slice';
import { personalInfo_ } from 'reducers/eCheckin/personal-info.slice';
import { proxyAccessConfig_ } from 'reducers/proxyaccess-config.slice';
import { fetchProxyAccessList, proxyAccessList_ } from 'reducers/proxyaccess-list.slice';
import { ProxyAccessListItem } from 'services';
import { isAgeWithinRange } from 'utils';
import { FEATURES } from 'utils/constants';
import { FeatureManager } from 'utils/featuremanager';

export const CpxUser: FunctionComponent = () => {
  const { account } = useContext(AuthContext);
  const proxyAccessDetails = useSelector(proxyAccessList_);
  const proxyConfig = useSelector(proxyAccessConfig_);
  const personalInfo = useSelector(personalInfo_);
  const currentPatientView = useSelector(CurrentPatientView_);
  const mainAccount = useSelector(MainPatientAccount_);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selected, setSelected] = useState<ProxyAccessListItem | undefined>(undefined);
  const [userInitials, setUserInitials] = useState<string>('');

  useEffect(() => {
    if (!_.isEmpty(personalInfo)) {
      const { DateOfBirth, FirstName, LastName, PatientID } = personalInfo;
      const currentUser = {
        DateOfBirth: DateOfBirth,
        FirstName: FirstName,
        LastName: LastName,
        PatientID: PatientID?.toUpperCase(),
      };
      if (_.isEmpty(mainAccount)) {
        dispatch(setMainAccount(currentUser));
        dispatch(setUserViewing(currentUser));
      }
    }
  }, [account, personalInfo]);

  useEffect(() => {
    if (account && _.isEmpty(proxyAccessDetails)) {
      dispatch(fetchProxyAccessList());
    }
  }, [account]);

  useEffect(() => {
    let initials = '';
    if (!currentPatientView) {
      initials = getUserInitial() + getUserSecondInitial();
    } else {
      const { FirstName, LastName } = currentPatientView;
      if (FirstName && LastName) {
        initials = FirstName.charAt(0) + LastName.charAt(0);
      }
      setSelected(currentPatientView);
    }
    setUserInitials(initials);
  }, [account, currentPatientView]);

  const getUserName = (): string => {
    if (account && account.name) {
      return account.name;
    }
    return '';
  };

  const getUserSecondInitial = (): string => {
    if (account && account.name) {
      const arr = account.name.split(' ');
      return arr[1] ? arr[1].charAt(0) : '';
    }
    return '';
  };
  const getUserInitial = (): string => {
    if (account && account.name && !selected) {
      return account.name.charAt(0);
    }
    return '';
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAcctClick = (elem: ProxyAccessListItem | undefined) => {
    setSelected(elem);
    // switch accounts
    if (elem) {
      dispatch(setUserViewing(elem));
    }
    dispatch(setUserSwitchedAccount());
  };

  const getProxyAccessListElements = () => {
    if (!_.isEmpty(proxyAccessDetails)) {
      // process each proxy
      const result = proxyAccessDetails.map((elem: ProxyAccessListItem, index: number) => {
        const { FirstName, LastName } = elem;
        return (
          <ListItem
            key={'proxy' + index}
            button
            onClick={() => handleAcctClick(elem)}
            className={selected === elem ? 'selected' : 'not-selected'}
          >
            <ListItemIcon>
              <AccountCircle htmlColor="#2196f3" />
            </ListItemIcon>
            <ListItemText primary={FirstName + ' ' + LastName} />
            {selected === elem ? <Done /> : <></>}
          </ListItem>
        );
      });
      return result;
    }
  };

  const showProxyMenuBaseOnCriteria = (): boolean => {
    let compare = false;
    if (proxyConfig) {
      const { criteriaToShowMenu } = proxyConfig;
      if (criteriaToShowMenu && criteriaToShowMenu.length > 0 && mainAccount?.DateOfBirth !== '') {
        // for now, take the first criteria
        const { basedOnAge, age, secondAge, operand } = criteriaToShowMenu[0];
        if (basedOnAge && mainAccount && mainAccount.DateOfBirth) {
          compare = isAgeWithinRange(mainAccount.DateOfBirth, age, secondAge, operand);
        } else {
          // always show
          compare = true;
        }
      }
    }
    return compare;
  };

  const open = Boolean(anchorEl);
  const id = open ? 'user-account' : undefined;

  return (
    <Box id="user-profile">
      <Button onClick={handleClick}>
        <Avatar
          alt="account-holder"
          className={
            currentPatientView?.PatientID === mainAccount?.PatientID ? 'avatar-icon' : 'avatar-linked-acct-icon'
          }
        >
          {userInitials}
        </Avatar>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="z11000"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="nav" aria-label="contacts" className="user-account-menu">
          <ListItem
            key="proxy0"
            button
            className={!selected ? 'selected' : 'not-selected'}
            onClick={() => handleAcctClick(mainAccount)}
          >
            <ListItemIcon>
              <AccountCircle htmlColor={!selected ? '#fff' : '#71aa42'} />
            </ListItemIcon>
            <ListItemText primary={getUserName()} />
            {selected === null ? <Done /> : <></>}
          </ListItem>
          {FeatureManager.isEnabled(FEATURES['Dependents']) &&
          !_.isEmpty(proxyAccessDetails) &&
          showProxyMenuBaseOnCriteria() ? (
            getProxyAccessListElements()
          ) : (
            <></>
          )}
        </List>
      </Popover>
    </Box>
  );
};
