import { configureStore, createAction } from '@reduxjs/toolkit';

import addToHomeScreenReducer from './add-to-home-screen.slice';
import configDetailsReducer from './appointment-config.slice';
import appointmentDetailsReducer from './appointment-details';
import cancelledAppointmentsReducer from './cancelled-appointments.slice';
import cancelSchedAppointmentDetailsByPhoneReducer from './cancelSchedule-appointment-details';
import careTeamItemConfigReducer from './careItems-config.slice';
import careTeamAllProvidersReducer from './careteam-allproviders.slice';
import careTeamForMessagingReducer from './careTeam-For-Messaging.slice';
import careTeamProviderReducer from './careTeamProviders.slice';
import consentFormListSlice from './consent-form/consent-form-list.slice';
import consentFormsSlice from './consent-form/consent-form.slice';
import currentPatientViewReducer from './current-patient-view.slice';
import alertDetailsReducer from './dasboard-alert.slice';
import healthDeptLogo from './DashBoardLogo.slice';
import healthDeptDetails from './Department.slice';
import healthInfoDocumentsReducer from './documents/upload-documents.slice';
import echeckinConfigReducer from './echeckin-config.slice';
import countiesReducer from './eCheckin/counties.slice';
import countriesSlice from './eCheckin/countries.slice';
import eCheckinPayloadReducer from './eCheckin/eCheckin-payload.slice';
import eCheckinFormIoSavedDataSlice from './eCheckin/get-form-saved-data.slice';
import insuranceDetailsReducer from './eCheckin/insurance-details.slice';
import lookupsReducer from './eCheckin/lookups.slice';
import personalInfoReducer from './eCheckin/personal-info.slice';
import practicesListSlice from './eCheckin/practices-list.slice';
import relationshipsReducer from './eCheckin/relationships.slice';
import religionsReducer from './eCheckin/religions.slice';
import requestActivationReducer from './eCheckin/request_submission.slice';
import requestActivationCodePracticesListReducer from './eCheckin/requestactivationcodepractices.slice';
import requestActivationCodeStatesReducer from './eCheckin/requestActivationCodestates.slice';
import requestActivationCodestatesWithCountry from './eCheckin/requestActivationCodestatesWithCountry.slice';
import requestActivationCountiesSlice from './eCheckin/requestactivationcounties.slice';
import requestActivationcountriesSlice from './eCheckin/requestActivationcountries.slice';
import statesReducer from './eCheckin/states.slice';
import statesWithCountryCodeSlice from './eCheckin/statesWithCountry.slice';
import profilePhotoSlice from './eCheckin/userInfo.slice';
import EHRConfigurationsReducer from './EHR-configurations/EHR-configurations.slice';
import featureDetailsReducer from './feature-details.slice';
import eCheckInFormDetails from './formio/eCheckin-form-details-get.slice';
import formData from './formio/form-data-get.slice';
import formDetails from './formio/form-get.slice';
import formIOTranslations from './formio/formio-translations.slice';
import futureAppointmentsReducer from './future-appointments.slice';
import loaderReducer from './general/loader.slice';
import toastReducer from './general/toast.slice';
import tokenReducer from './general/token.slice';
import healthSummaryConfig from './health-summary-config.slice';
import vitalDetailsReducer from './healthSummary/vitals/vitals-details.slice';
import vitalSummariesReducer from './healthSummary/vitals/vitals-summary.slice';
import kioskPersonalDetails from './kiosk-login.slice';
import fetchKioskAppointments from './kiosk/kiosk.getAppointments.slice';
import kioskHealthDeptLogo from './kiosk/kioskHealthDepartmentLogo.slice';
import labResultsReducer from './lab-results-config.slice';
import medicationConfig from './medications/medication-config.slice';
import medicationDetails from './medications/medications-details.slice';
import medicationSummary from './medications/medications-summary.slice';
import messageItemsConfigReducer from './message-config.slice';
import pastAppointmentsReducer from './past-appointments.slice';
import workingHours from './practice-details.slice';
import projectDetailsReducer from './project-details.slice';
import proxyAccessTypeReducer from './proxy-request-wizard/proxy-access-type.slice';
import proxyPatientInfoReducer from './proxy-request-wizard/proxy-patient-info.slice';
import proxyRequestPayloadReducer from './proxy-request-wizard/proxy-payload.slice';
import proxyTermsReducer from './proxy-request-wizard/proxy-terms-agreement.slice';
import proxyDocumentsReducer from './proxy-request-wizard/proxy-upload-documents.slice';
import proxyAccessConfigReducer from './proxyaccess-config.slice';
import proxyAccessListReducer from './proxyaccess-list.slice';
import proxyRequestConfigReducer from './proxyrequest-config.slice';
import futureAppointmentQuestionnaire from './questionnaire-wizard/future-appointment-questionnaire.slice';
import pastAppointmentQuestionnaire from './questionnaire-wizard/past-appointment-questionnaire.slice';
import questionnaireFormIOPayload from './questionnaire-wizard/questionnaire-formio-save.slice';
import questionnaireList from './questionnaireList.slice';
import refusedAppointmentsReducer from './refused-appointments.slice';
import requestAppointmentReducer from './request-appointments-slice';
import reqstCancelAppointmentReducer from './requestCancel-appointment.slice';
import requestUpcomingCancelAppReducer from './requestCancel-Upcoming-appointment';
import requestedCancellationAppointmentsReducer from './requested-cancellation-appointments.slice';
import reSchedAppointmentDetailsByPhoneReducer from './reschedule-appointment-details';
import schedAppointmentDetailsByPhoneReducer from './schedule-appointment-details';
import scheduleRequestQuestionnaire from './schedule-appointment-questionnaire';
import scheduleAppointmentConfigurationReducer from './schedule-wizard/schedule-appointment-configuration';
import scheduleAppointmentTypesReducer from './schedule-wizard/schedule-appointment-types';
import scheduleLocations from './schedule-wizard/schedule-locations';
import schedulePayload from './schedule-wizard/schedule-payload.slice';
import scheduleQuestions from './schedule-wizard/schedule-questions';
import bookingSlotPayload from './self-schedule-wizard/schedule-appointment-booking-slots';
import selfScheduleAppointmentTypesReducer from './self-schedule-wizard/schedule-appointment-types';
import selfScheduleLocations from './self-schedule-wizard/schedule-locations';
import selfSchedulePayload from './self-schedule-wizard/schedule-payload.slice';
import telehealthLinkReducer from './self-schedule-wizard/telehealth-link.slice';
import visitRecordsConfig from './visit-records-config.slice';

export const rootReducer = {
  projectDetails: projectDetailsReducer,
  alertDetails: alertDetailsReducer,
  appointmentDetails: appointmentDetailsReducer,
  schedAppointmentDetailsByPhone: schedAppointmentDetailsByPhoneReducer,
  reSchedAppointmentDetailsByPhone: reSchedAppointmentDetailsByPhoneReducer,
  cancelSchedAppointmentDetailsByPhone: cancelSchedAppointmentDetailsByPhoneReducer,
  loader: loaderReducer,
  healthDeptDetails: healthDeptDetails,
  healthDeptLogo: healthDeptLogo,
  featureDetails: featureDetailsReducer,
  workingHours: workingHours,
  /**--- eCheckin-----*/
  insuranceDetails: insuranceDetailsReducer,
  personalInfo: personalInfoReducer,
  states: statesReducer,
  requestActivationCodeStates: requestActivationCodeStatesReducer,
  statesWithCountryCode: statesWithCountryCodeSlice,
  requestActivationCodestatesWithCountryCode: requestActivationCodestatesWithCountry,
  religions: religionsReducer,
  relationships: relationshipsReducer,
  counties: countiesReducer,
  requestActivationCounties: requestActivationCountiesSlice,
  requestActivationCountries: requestActivationcountriesSlice,
  lookups: lookupsReducer,
  eCheckinPayload: eCheckinPayloadReducer,
  requestActivationPayload: requestActivationReducer,
  countries: countriesSlice,
  practices: practicesListSlice,
  requestActivationCodePractices: requestActivationCodePracticesListReducer,
  eCheckinFormIoSavedData: eCheckinFormIoSavedDataSlice,
  /**--- eCheckin-----*/
  toastMessages: toastReducer,
  appointmentConfigs: configDetailsReducer,
  echeckinConfigItems: echeckinConfigReducer,
  careTeamItemsConfig: careTeamItemConfigReducer,
  careTeamProviderDetails: careTeamProviderReducer,
  careTeamAllProviders: careTeamAllProvidersReducer,
  /**--schedule-wizard----*/
  scheduleAppointmentConfiguration: scheduleAppointmentConfigurationReducer,
  scheduleAppointmentTypes: scheduleAppointmentTypesReducer,
  scheduleLocations: scheduleLocations,
  scheduleAppPayload: schedulePayload,
  scheduleQuestions: scheduleQuestions,
  profilePhoto: profilePhotoSlice,
  /**--schedule-wizard----*/

  /**--Request appointment  **/
  requestAppointmentDetails: requestAppointmentReducer,
  /**--Past appointments */
  pastAppointments: pastAppointmentsReducer,
  /**--Future appointments */
  futureAppointments: futureAppointmentsReducer,
  /**--Request appointment  **/
  reqCancelAppointments: reqstCancelAppointmentReducer,
  /**--Request upcoming cancel appointment  **/
  reqCancelUpcomingAppointments: requestUpcomingCancelAppReducer,
  /**--Request Appointment Questionnaire**/
  scheduleRequestQuestionnaire: scheduleRequestQuestionnaire,
  /**--Cancelled Appointments **/
  cancelledAppointments: cancelledAppointmentsReducer,
  /**--Requested Cancellation Appointments **/
  requestedCancellationAppts: requestedCancellationAppointmentsReducer,
  /**--Refused Appointment Requests **/
  refusedAppointments: refusedAppointmentsReducer,
  /**--Checking token expiration **/
  tokenStatus: tokenReducer,

  /** Form.IO related */
  formDetails: formDetails,
  formData: formData,
  eCheckInFormDetails: eCheckInFormDetails,
  formIOTranslations: formIOTranslations,

  /** Questionnaire Wizard */
  pastAppointmentQuestionnaire: pastAppointmentQuestionnaire,
  futureAppointmentQuestionnaire: futureAppointmentQuestionnaire,
  questionnaireFormIOPayload: questionnaireFormIOPayload,

  /**Health Summary Config */
  healthSummaryConfig: healthSummaryConfig,
  vitalsSummary: vitalSummariesReducer,
  vitalDetails: vitalDetailsReducer,

  /** Medication */
  medicationsSummary: medicationSummary,
  medicationDetails: medicationDetails,
  medicationConfig: medicationConfig,

  /** Message Config */
  messageItemsConfig: messageItemsConfigReducer,

  /** Lab Results Config */
  labResultsConfig: labResultsReducer,

  /** Care Team For Messaging*/
  careTeamForMessaging: careTeamForMessagingReducer,

  /** Add to home screen  */
  addToHomeScreen: addToHomeScreenReducer,

  /** Documents */
  healthInfoDocuments: healthInfoDocumentsReducer,

  /** Kiosk */
  kioskPersonalDetails: kioskPersonalDetails,
  kioskAppointments: fetchKioskAppointments,
  kioskHealthDeptLogo: kioskHealthDeptLogo,

  /** EHR Consent Forms */
  consentFormList: consentFormListSlice,
  consentForms: consentFormsSlice,

  /**EHR Questionnaire Forms */
  questionnaireList: questionnaireList,

  /**Self Schedule */
  selfScheduleAppointmentTypes: selfScheduleAppointmentTypesReducer,
  selfScheduleLocations: selfScheduleLocations,
  bookingSlotPayload: bookingSlotPayload,
  selfScheduleAppPayload: selfSchedulePayload,
  telehealthLink: telehealthLinkReducer,

  /**Visit Records config */
  visitRecordsConfig: visitRecordsConfig,

  /**EHR Configurations */
  configurations: EHRConfigurationsReducer,

  /**Proxy Access Config */
  proxyAccessConfig: proxyAccessConfigReducer,

  /**Proxy Request Config */
  proxyRequestConfig: proxyRequestConfigReducer,

  /** Proxy Access List */
  proxyAccessList: proxyAccessListReducer,

  /** Viewing which patient account */
  currentPatientView: currentPatientViewReducer,

  /** Request Proxy Access Wizard */
  proxyAccessType: proxyAccessTypeReducer,
  proxyPatientInfo: proxyPatientInfoReducer,
  proxyRequestPayload: proxyRequestPayloadReducer,
  proxyTerms: proxyTermsReducer,
  proxyDocuments: proxyDocumentsReducer,
};

export const resetAll = createAction('RESET_ALL');

const store = configureStore({
  reducer: rootReducer,
  // redux devtools is enabled only for dev env
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //Ignore the event (not serializable) we stashed for add to homescreen
      serializableCheck: {
        ignoredActions: ['addToHomeScreen/storeInstallPrompt'],
        ignoredPaths: ['addToHomeScreen.deferredAndroidEvent'],
      },
    }),
});

export default store;
