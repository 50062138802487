import './../cpx-billing.component.styles.scss';

import { Button } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InnerHeader from 'components/common/InnerHeader/InnerHeader-component';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export const TransactionStatus: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="wrapper">
      <InnerHeader className="header" heading={t('billing.billing')} />
      <main>
        <div className="trans-status">
          <CheckCircleIcon className="success" />
          <h2>Thank You!</h2>
          <p className="success">Payment done Successfully</p>
          <small>You will be redirected to the home page shortly or click here to return to the home page</small>
          <Button variant="contained" color="primary">
            Home
          </Button>
        </div>

        <div className="trans-status">
          <CancelIcon className="error" />
          <h2>Oh No!</h2>
          <p>Something went wrong</p>
          <small className="error">
            We are not able to process your payment. <br /> Please try again.
          </small>
          <Button variant="outlined" color="primary">
            Try Again
          </Button>
        </div>
      </main>
      <br />
      <br />
      <br />
    </div>
  );
};
