import { Gender } from 'models/dropdowns';
import { tokenAuthApiInstance } from 'utils/auth';

import { PERSONAL_INFO_ENDPOINT, personalInfoWithPracticeCalendarIDEndPoint } from '../constants';

export type PersonalInfo = {
  PatientID: string;
  PaperChartNumber: string;
  PMRecordNumber: string;
  Title: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Suffix: string;
  PreferredName: string;
  DateOfBirth: string;
  DateOfDeath: string;
  Gender: keyof typeof Gender;
  GenderID: string;
  MaritalStatus: string;
  MaritalStatusID: number;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  State: string;
  County: string;
  Zipcode: string;
  HomePhoneNumber: string;
  WorkPhoneNumber: string;
  MobileNumber: string;
  EmailAddress: string;
  PatientPhotoLocation: string;
  GenderIdentity: string;
  GenderIdentityID: number;
  SendSMSNotification: boolean;
  SendEmailNotification: boolean;
  SexualOrientation: string;
  SexualOrientationID: number;
  SexAssignedAtBirth: string;
  Religion: string;
  UserID: string;
};

export const getPersonalInfo = async (): Promise<PersonalInfo> => {
  const response = await tokenAuthApiInstance.authAxios().get(PERSONAL_INFO_ENDPOINT);
  return response.data;
};

export const getPersonalInfoWithPracticeCalendarId = async (practiceCalendarID: string): Promise<PersonalInfo> => {
  const response = await tokenAuthApiInstance
    .authAxios()
    .get(personalInfoWithPracticeCalendarIDEndPoint(practiceCalendarID));
  return response.data;
};
